import React, { Component } from "react"
import styled from "styled-components"
import Resources from "../../resources/mlp-resources.json"
import ItemLogo from "./item-logo"
import ItemHolding from "./item-holding"
import Fade from "react-reveal/Fade"
import { useSiteContent } from "../hooks/use-site-content"

const WeArePartMainDiv = styled.div`
  width: 100%;
  padding-top: 30px;
`

const WeArePartHolding = styled.div`
  .holding-items {
    animation: fadeOut 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      display: visible;
    }
    25% {
      opacity: 0;
      display: none;
    }
    50% {
      opacity: 0;
      display: none;
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
`

const WeArePartLogos = styled.div`
  margin-top: -46vh;

  .logo-items {
    animation-delay: 2s;
    animation: fadeIn 2s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      display: none;
    }
    25% {
      opacity: 0;
      display: none;
    }
    50% {
      opacity: 0;
      display: none;
    }
    100% {
      opacity: 1;
      display: visible;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    margin-top: -158vh;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-top: -158vh;
  }
`

const WeArePartWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 3em;
  margin-top: 40px;

  @media only screen and (min-width: 992px) {
    .holding1 {
      grid-column: 1/1;
    }
    .holding2 {
      grid-column: 2/2;
    }
    .holding3 {
      grid-column: 3/3;
    }

    .view-holding {
      display: none;
    }
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`

const WeArePartGrid = styled.div`
  height: 41vh;
  position: relative;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    height: 47vh;
    width: 47vh;
    position: relative;

    .view-holding {
      display: visible;
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    height: 47vh;
    width: 47vh;
    position: relative;

    .view-holding {
      display: visible;
    }
  }
`

const WeArePartTitle = styled.label`
  font-family: var(${Resources.we_are_part.title.font});
  color: var(${Resources.we_are_part.title.color});
  font-size: ${Resources.we_are_part.title.size};
  text-transform: uppercase;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.contact_form.title.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.contact_form.title.size_med};
  }
`

class WeArePart extends Component {
  constructor(props) {
    super(props)
    this.state = { addClass: false }
    this.handleScroll = this.handleScroll.bind(this)
  }

  handleScroll() {
    const weArePart = document.querySelector("#we-are-part")
    const posRoundCent = Math.round(weArePart.getBoundingClientRect().y / 100) * 100
    const posRoundDec = Math.round(weArePart.getBoundingClientRect().y / 10) * 10

    /* Evaluando tamaño de dispositivos */
    if (!this.state.addClass) {
      if (window.innerWidth > 768) {
        if (posRoundCent <= 700) {
          this.setState({ addClass: !this.state.addClass })
        }
      } else {
        if(window.innerHeight < 731)  {
          if (posRoundDec <= 550) {
            this.setState({ addClass: !this.state.addClass })
          }
        }else{
          if(posRoundDec <= 660) {
            this.setState({ addClass: !this.state.addClass })
          }
        }
      }
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll)
  }

  render() {
    let fadeOutClass = [""]
    let fadeInClass = [""]
    if (this.state.addClass) {
      fadeOutClass.push("holding-items")
      fadeInClass.push("logo-items")
    }

    return (
      <WeArePartMainDiv id={"we-are-part"}>
        <div className="container">
          <Fade up>
            <WeArePartTitle>{Resources.we_are_part.title.label}</WeArePartTitle>
          </Fade>
          <WeArePartHolding>
            <WeArePartWrapper className={fadeOutClass.join(" ")}>
              <WeArePartGrid className="holding1 view-holding">
                <ItemHolding />
              </WeArePartGrid>
              <WeArePartGrid className="holding2">
                <ItemHolding />
              </WeArePartGrid>
              <WeArePartGrid className="holding3 view-holding">
                <ItemHolding />
              </WeArePartGrid>
            </WeArePartWrapper>
          </WeArePartHolding>
          <WeArePartLogos>
            <WeArePartWrapper className={fadeInClass.join(" ")}>
              <WeArePartGrid className="">
                <ItemLogo type="sofom" />
              </WeArePartGrid>
              <WeArePartGrid className="">
                <ItemLogo type="servicios" />
              </WeArePartGrid>
              <WeArePartGrid className="">
                <ItemLogo type="mlp" />
              </WeArePartGrid>
            </WeArePartWrapper>
          </WeArePartLogos>
        </div>
      </WeArePartMainDiv>
    )
  }
}

export default props => {
  const SiteContent = useSiteContent()
  return <WeArePart siteContent={SiteContent} {...props} />
}
