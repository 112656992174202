import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from 'gatsby-background-image'

import Resources from "../../resources/mlp-resources.json"
import { useSiteContent } from "../hooks/use-site-content"

const WhoTitle = styled.span`
  font-family: var(${Resources.who_are_we.title.font});
  font-size: ${Resources.who_are_we.title.size};
  color: var(${Resources.who_are_we.title.color});
  text-transform: uppercase;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.who_are_we.title.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.who_are_we.title.size_med};
  }
`

const WhoText = styled.span`
  font-family: var(${Resources.who_are_we.text.font});
  font-size: ${Resources.who_are_we.text.size};
  color: var(${Resources.who_are_we.text.color});

  line-height: 1.7em;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.who_are_we.text.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.who_are_we.text.size_med};
  }
`

const WhoTextContainer = styled.div`
  margin-top: 20px;
`

const ColorfulSection = styled.div`
  background-color: var(--primary-color);
  
  display: grid;
  grid-template-columns: 6fr 4fr;

  margin-top: 6em;

  @media only screen and (max-width: 767px){
    grid-template-columns: 1fr;
    grid-template-rows: 4.5fr 5fr;
  }
`

const StyledBgDiv = styled(BackgroundImage) `
  @media only screen and (max-width: 767px){
    grid-row: 1;
    margin-bottom: -10%;
  }
`

const ColorfulTextHolder = styled.div`
  text-align: center;
  
  @media only screen and (min-width: 1401px) {
    max-width: 30em;
    text-align: right;
    justify-self: end;
    width: 100%;
    padding: 0;
    margin: 18vh 10em 18vh 18vw;
  }

  @media only screen and (min-width: 992px) and (max-width: 1400px) {
    padding: 18vh 18vw;
  }
  @media only screen and (max-width: 991px) {
    padding: 13vh 13vw;
  }
`

const ColorfulText = styled.span`
  font-family: var(${Resources.who_are_we.colorful.font});
  font-size: ${Resources.who_are_we.colorful.size};
  color: var(${Resources.who_are_we.colorful.color});
  span {
    text-align: center;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.who_are_we.colorful.size_small};
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.who_are_we.colorful.size_med};
  }
`

class WhoAreWe extends Component {
  render() {
    const {about_us: {description, banner_label}} = this.props.siteContent
    return (
      <div id="WhoAreWeSection">
        <div className="container">
          <WhoTitle>{Resources.who_are_we.title.label}</WhoTitle>
          <WhoTextContainer>
            <WhoText>{description}</WhoText>
          </WhoTextContainer>
        </div>
        <ColorfulSection>
          <ColorfulTextHolder>
            <ColorfulText dangerouslySetInnerHTML={{__html: banner_label }}/>
          </ColorfulTextHolder>
          <StaticQuery
            query={graphql`
              query {
                colorfulBkg: file(name: { eq: "banner_img" }) {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 839, maxHeight: 681) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            `}
            render={data => (
              <StyledBgDiv
                Tag={"div"}
                className={"bg-whoarewe"}
                fluid={[data.colorfulBkg.childImageSharp.fluid]}
                backgroundColor={`var(${Resources.cover.bg_color})`}
              ></StyledBgDiv>
            )}
          />
        </ColorfulSection>
      </div>
    )
  }
}

export default props => {
  const SiteContent = useSiteContent()
  return (<WhoAreWe siteContent={ SiteContent } {...props}/>)
}
