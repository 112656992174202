import React, { useState } from "react"
import styled from "styled-components"
import Resources from "../../resources/mlp-resources.json"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useSpring, animated as a } from "react-spring"
import { useSiteContent } from "../hooks/use-site-content"

const MainItemLogo = styled.div`
  width: 100%;
  height: 100%;

  .contentCard {
    position: absolute;
    width: 33vh;
    height: 33vh;
    will-change: transform, opacity;
    padding: 2em;
    align-items: center;

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
      width: 38vh;
      height: 38vh;
      padding: 1.3em;    
      justify-items: center;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      width: 38vh;
      height: 38vh;
      justify-items: center;
    }
  }

  .bg-sofom, .bg-serv, .bg-mlp {
    padding: 30px;
  }

  .bg-sofom {
    background-color: var(${Resources.we_are_part.bg_color_sofom}) !important;
  }

  .bg-serv {
    background-color: var(${Resources.we_are_part.bg_color_serv}) !important;
  }

  .bg-mlp {
    background-color: var(${Resources.we_are_part.bg_color_mlp}) !important;
  }
`

const ItemLogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  justify-items: center;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: grid;
    grid-gap: 1em;
    justify-content: normal;
    align-items: center;
    justify-items: center;
  }
`

const ItemLogoImgWrapper = styled.div`
  /*width: 100px;
    height: 100px;
    padding: 3em;*/
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
  }
`

const ItemLogoTextWrapper = styled.div`
  font-family: var(${Resources.we_are_part.text.font});
  font-size: ${Resources.we_are_part.text.size};
  color: var(${Resources.we_are_part.text.color});
  padding: 12px 5px;

  a:link, a:hover, a:active, a:visited {
      color: var(${Resources.we_are_part.text.color});
  }

  .btn {
    position: absolute;
    bottom: 2em;
  }

  .btn:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    text-align: left;
  }

  .accent-text {
    color: var(${Resources.we_are_part.title.color}) !important;
    font-weight: bold !important;
  }
`

const ItemLogo = ({ type }) => {
  let image, text, bgLogo, showBtn, url
  const {
    we_are_part: { afisofom_desc, afiservicios_desc }
  } = useSiteContent()

  const [flipped, set] = useState(false)
  const { transform, opacity } = useSpring({
    opacity: flipped ? 0 : 1,
    transform: `perspective(600px) rotateX(${flipped ? 0 : 180}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  const data = useStaticQuery(graphql`
    query Images {
      mlp: file(relativePath: { eq: "main-logo.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      sofom: file(relativePath: { eq: "main-logo-sofom.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      serv: file(relativePath: { eq: "main-logo-serv.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  switch (type) {
    case "sofom":
      image = (
        <Img
          fluid={data.sofom.childImageSharp.fluid}
          className=""
          imgStyle={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        />
      )
      text = afisofom_desc
      url = "https://afisofom.com"
      bgLogo = "bg-sofom contentCard"
      showBtn = true
      break
    case "servicios":
      image = (
        <Img
          fluid={data.serv.childImageSharp.fluid}
          className=""
          imgStyle={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        />
      )
      text = afiservicios_desc
      url = "https://afiservicios.net"
      bgLogo = "bg-serv contentCard"
      showBtn = true
      break
    default:
      image = (
        <Img
          fluid={data.mlp.childImageSharp.fluid}
          className=""
          imgStyle={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        />
      )
      text = ""
      url = "https://multilayerproperties.com"
      bgLogo = "bg-mlp contentCard"
      showBtn = false
      break
  }

  return (
    <MainItemLogo>
      <ItemLogoWrapper
        onMouseEnter={() => type !== "mlp" && set(state => !state)}
        onMouseLeave={() => type !== "mlp" && set(state => !state)}
      >
        <a.div
          className={bgLogo}
          style={{
            opacity,
            transform: transform.interpolate(t => `${t} rotateX(180deg)`),
          }}
        >
          <ItemLogoImgWrapper>{image}</ItemLogoImgWrapper>
        </a.div>

        <a.div
          className={bgLogo}
          style={{ opacity: opacity.interpolate(o => 1 - o), transform }}
        >
          <ItemLogoTextWrapper>
            {text}
            <br></br>
            <br></br>
            {showBtn ? (
              <a className="btn" size="small" href={url} rel="noreferrer" target="_blank" >
                Visitar
              </a>
            ) : null}
          </ItemLogoTextWrapper>
        </a.div>
      </ItemLogoWrapper>
    </MainItemLogo>
  )
}

ItemLogo.propTypes = {
  type: PropTypes.oneOf(["sofom", "servicios", "mlp"]),
}

ItemLogo.defaultProps = {
  type: "mlp",
}

export default ItemLogo
