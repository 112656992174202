import React from "react"
import styled from "styled-components"
import Resources from "../../resources/mlp-resources.json"
import CustomButton from './button';
import Zoom from 'react-reveal/Zoom'

const ContactSectionContainer = styled.div`
  width: 100%;
  min-height: 300px;
  height: auto;
  opacity: ${Resources.contact_section.bg_opacity} !important;
  background-color: var(${Resources.contact_section.bg_color}) !important;
`;

const ContactSectionText = styled.div`
  width: 100%;
  min-height: 300px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContactSectionGeneralContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-gap: 1em;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 3fr 1fr;
  }
`;

const ContactSectionInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
`;

const ContactSectionInfo = styled.div`
  text-align: center;
`;

const ContactSectionTitle = styled.span`
  font-family: var(${Resources.contact_section.title.font});
  font-size: ${Resources.contact_section.title.size};
  color: var(${Resources.contact_section.title.color});
  text-transform: uppercase;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.contact_section.title.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.contact_section.title.size_med};
  }
`;

const ContactSectionSubtitle = styled.span`
  font-family: var(${Resources.contact_section.subtitle.font});
  font-size: ${Resources.contact_section.subtitle.size};
  color: var(${Resources.contact_section.subtitle.color});
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.contact_section.subtitle.size_small};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.contact_section.subtitle.size_med};
  }
`;

const ContactSection = () => (
  <ContactSectionContainer>
    <Zoom>
    <ContactSectionText>
      <ContactSectionGeneralContainer className="container">
        <div />
        <ContactSectionInfoContainer>
          <ContactSectionInfo>
            <ContactSectionTitle>
              {Resources.contact_section.title.label}
            </ContactSectionTitle>
          </ContactSectionInfo>
          <ContactSectionInfo>
            <ContactSectionSubtitle>
              {Resources.contact_section.subtitle.label}
            </ContactSectionSubtitle>
          </ContactSectionInfo>
          <ContactSectionInfo>
            <CustomButton label={Resources.contact_section.button.label}
                          view={Resources.contact_section.button.view}
                          fullWidth={false}
                          containerStyle={{ marginTop: '17.5px' }}
                          onClick={() => onContactClick()} />
          </ContactSectionInfo>
        </ContactSectionInfoContainer>
        <div />
      </ContactSectionGeneralContainer>
    </ContactSectionText>
    </Zoom>
  </ContactSectionContainer>
);

const onContactClick = () => {
  const element = document.getElementById('contact-form-id');
  if (element) {
    const position = element.offsetTop - Resources.navbar.offset_top;
    window.scrollTo(0, position);
  }
};

export default ContactSection;
