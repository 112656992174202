import React, { Component } from "react"
import styled from "styled-components"
import Resources from "../../resources/mlp-resources.json"
import SpecialityAreaItem from "./area-item"
import Fade from "react-reveal/Fade"
import { useSiteContent } from "../hooks/use-site-content"

const AreaConf = Resources.areas_esp

const SpecialtyAreaSection = styled.section`
  
  background-color: #f3f3f3;
  padding: 40px 0;
`

const SpecialityAreaWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  margin-top: 40px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const SpecialityAreaTitle = styled.label`
  font-family: var(${AreaConf.title.font});
  color: var(${AreaConf.title.color});
  font-size: ${AreaConf.title.size};
  text-transform: uppercase;
`

// POPUPS
const PopupHolder = styled.div`
`

const BGDiv = styled.div`
  background-color: var(--six-color);
  filter: opacity(80%);
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
`

const PopupDiv = styled.div`
  position: fixed;
  width: 37.5vw; 
  top: 20vh;
  left: 27.5vw;
  background-color: var(--four-color);
  border-radius: 20px;
  padding: 3em;
  z-index: 10001;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    min-width: 66%;
    max-width: 66%;
    margin: 0 auto;
    left: auto;
    min-height: 350px;
    max-height: 350px;
    overflow-y: auto;
    padding: 30px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin: 0 auto;
    min-height: 400px;
    max-height: 400px;
    padding: 30px;
  }
`

const PopupTitle = styled.h2`
  font-family: var(--font-bold);
  margin-bottom: 2em;
`

const PopupText = styled.p`
  line-height: 1.8em;
  margin-bottom: 5em;

  .accent-text {
    font-family: var(--font-bold);
  }
`

class SpecialityArea extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBox: false,
      textItem: "",
      popupTitle: "",
      popupText: ""
    }
  }

  toggleBox(textName, description) {
    // Set state and call TextPopup with the callback function
    this.setState({
      showBox: !this.state.showBox,
      textItem: textName
    }, function() {
      this.TextPopup()
    })
  }

  TextPopup() {
    if (this.state.textItem) {
      const { areas_esp } = this.props.siteContent
      this.setState({
        popupTitle: areas_esp[this.state.textItem].title,
        popupText: areas_esp[this.state.textItem].description
      })
    }

    if (this.state.showBox) {
      document.body.style =
        "overflow-y:hidden; position: relative; margin-right: var(--widthReflow);"
    } else {
      document.body.style =
        "overflow-y:visible; position: static; margin-right: 0;"
    }
  }

  render() {
    return (
      <SpecialtyAreaSection>
        <div className="container" id="specialty-areas">
          {this.state.showBox ? (
            <PopupHolder onClick={() => this.toggleBox()}>
              <PopupDiv>
                <PopupTitle>{this.state.popupTitle}</PopupTitle>
                <PopupText dangerouslySetInnerHTML={{ __html: this.state.popupText }}/>
              </PopupDiv>
              <BGDiv/>
            </PopupHolder>
          ) : null}
          <Fade up>
            <SpecialityAreaTitle>
              {AreaConf.title.label}
            </SpecialityAreaTitle>
            <SpecialityAreaWrapper>
              <SpecialityAreaItem type="administration" onClick={() => this.toggleBox("elem1")}/>
              <SpecialityAreaItem type="monetization" onClick={() => this.toggleBox("elem2")}/>
              <SpecialityAreaItem type="hospitable" onClick={() => this.toggleBox("elem3")}/>
              <SpecialityAreaItem type="borrow" onClick={() => this.toggleBox("elem4")}/>
              <SpecialityAreaItem type="debt" onClick={() => this.toggleBox("elem5")}/>
              <SpecialityAreaItem type="investment" onClick={() => this.toggleBox("elem6")}/>
            </SpecialityAreaWrapper>
          </Fade>
        </div>
      </SpecialtyAreaSection>
    )
  }
}

export default props => {
  const SiteContent = useSiteContent()
  return (<SpecialityArea siteContent={SiteContent} {...props} />)
}
