import React from "react"
import styled from "styled-components"
import Resources from "../../resources/mlp-resources.json"
import PropTypes from "prop-types"
import AdministrationImage from '../images/svg/trazado151.svg'
import MonetizationImage from '../images/svg/ahorro.svg'
import HospitableImage from '../images/svg/hospital.svg'
import BorrowImage from '../images/svg/contrato.svg' 
import DebtImage from '../images/svg/dinero.svg'
import InvestmentImage from '../images/svg/prestamo.svg'
import { useSiteContent } from "../hooks/use-site-content"

const AreaItemWrapper = styled.div`
    width: 100%;
    height: auto;
    display: block;

    :hover {
        cursor:pointer;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: 0.2fr 0.8fr;
        grid-gap: 1em;
        justify-content: flex-start;
        align-items: center;
    }
`;

const AreaItemImgWrapper = styled.div`
    background-color: var(${Resources.areas_esp.bg_color2});
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 15px;
    margin: 15px auto;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 30px;

    .img {
        width: 70px;
        height: 70px;
        display: block;
        margin: auto;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0;
        
        .img {
            max-height: 100%;
        }
    }
`;

const AreaItemTextWrapper = styled.div`
    font-family: var(${Resources.areas_esp.font});
    font-size: ${Resources.areas_esp.size};
    color: var(${Resources.areas_esp.color});
    text-align: center;

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 767px) {
        text-align: left;
    }

    .accent-text {
        color: var(${Resources.areas_esp.title.color}) !important;
        font-weight: bold !important;
    }
`;

const AreaItem = ({ type, onClick }) => {
    let image, text;
    const {areas_esp: {elem1, elem2, elem3, elem4, elem5, elem6}} = useSiteContent()
    switch (type) {
        case 'administration':
            image = <AdministrationImage className="img" />
            text = elem1.title;
            break;
        case 'monetization':
            image = <MonetizationImage className="img" />
            text = elem2.title;
            break;
        case 'hospitable':
            image = <HospitableImage className="img" />
            text = elem3.title;
            break;
        case 'borrow':
            image = <BorrowImage className="img" />
            text = elem4.title;
            break;
        case 'debt':
            image = <DebtImage className="img" />
            text = elem5.title;
            break;
        default:
            image = <InvestmentImage className="img" />
            text = elem6.title;
            break;
    }

    return (
        <AreaItemWrapper className="svg-icon" onClick={onClick}>
            <AreaItemImgWrapper>
                {image}
            </AreaItemImgWrapper>
            <AreaItemTextWrapper>{text}</AreaItemTextWrapper>
        </AreaItemWrapper>
    );
};

AreaItem.propTypes = {
    type: PropTypes.oneOf([
        'administration',
        'monetization',
        'hospitable',
        'borrow',
        'debt',
        'investment'
    ]),
    onClick: PropTypes.func.isRequired
};

AreaItem.defaultProps = {
    type: 'investment'
};

export default AreaItem;
