import React  from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.css"
import ContactSection from "../components/contact-section"
import Cover from '../components/cover'
import WhoAreWe from "../components/who-are-we"
import SpecialityArea from "../components/speciality-area"
import WeArePart from "../components/we-are-part"
import styled from "styled-components"

const SectionSpace = styled.div`
  margin-top: 120px;
    
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    margin-top: 80px;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-top: 80px;
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Inicio"/>
      <Cover />
      <SectionSpace />
      <WhoAreWe />
      <ContactSection />
      <SpecialityArea />
      <WeArePart />
      <SectionSpace />
    </Layout>
  );
};

export default IndexPage
