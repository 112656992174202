import React from "react"
import styled from "styled-components"
import Resources from "../../resources/mlp-resources.json"
import { graphql, StaticQuery } from "gatsby"
import CustomButton from "./button"
import BackgroundImage from 'gatsby-background-image'
import Fade from 'react-reveal/Fade'
import "../styles/background-image.css"
import { useSiteContent } from "../hooks/use-site-content"

const CoverWrapper = styled.div`
  overflow: hidden;
  margin-top: -80px;
  --min-height-med: calc(100vh);
  --min-height-big: calc(100vh);

  @media only screen and (min-width: 992px) {
    height: var(--min-height-med);
    overflow: hidden;
  }

  @media only screen and (max-width: 991px) {
    height: var(--min-height-med);
    overflow: hidden;
  }
`

const CoverInfoSection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  padding-top: 65px;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    padding-top: 0;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding-top: 80px;
    height: calc(100vh - 80px);
    overflow: hidden;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    padding-top: 92px;
  }
`

const CoverContentContainer = styled.div`
  min-height: calc(100vh - 80px);
  height: auto;
`

const CoverContentSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  grid-gap: 1em;
  
  width: 100%;
  height: calc(100vh - 80px);
  justify-content: center;
  align-items: center;


  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`

const CoverTextContainer = styled.div`
  display: block;
  margin: auto 0;
  @media only screen and (min-width: 992px) {
    width: 31vw;
  }
`

const CoverTextDisplay = styled.div`
  text-align: left;
  margin: 30px 0;

  .cover-title {
    font-family: var(${Resources.cover.title.font});
    font-size: ${Resources.cover.title.size};
    color: var(${Resources.cover.title.color});
    text-transform: uppercase;
  }

  .cover-subtitle {
    font-family: var(${Resources.cover.subtitle.font});
    font-size: ${Resources.cover.subtitle.size};
    color: var(${Resources.cover.subtitle.color});
    line-height: ${Resources.cover.subtitle.line_height};
    padding-bottom: 10px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    text-align: center;

    .cover-title {
      font-size: ${Resources.cover.title.size_small};
    }

    .cover-subtitle {
      font-size: ${Resources.cover.subtitle.size_small};
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    text-align: center;

    .cover-title {
      font-size: ${Resources.cover.title.size_med};
    }

    .cover-subtitle {
      font-size: ${Resources.cover.subtitle.size_med};
    }
  }
`

const Cover = () => {
  const {cover:{ title, description }} = useSiteContent()
  return (
  <StaticQuery
    query={graphql`
      query {
        cover: file(name: { eq: "cover_background" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        },
        cover_movil: file(name: { eq: "cover_background_movil" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        },
      }
    `}
    render={data => (
      <CoverWrapper>
          <BackgroundImage
            Tag={"div"}
            className={"bg-cover"}
            fluid={[data.cover.childImageSharp.fluid]}
            backgroundColor={`var(${Resources.cover.bg_color})`}
          >
            <CoverInfoSection>
              <div className="container">
                <CoverContentContainer>
                  <CoverContentSection>
                    <CoverTextContainer>
                      <CoverTextDisplay>
                    <span className="cover-title">
                      { title }
                    </span>
                      </CoverTextDisplay>
                      <CoverTextDisplay>
                    <span className="cover-subtitle">
                      { description }
                    </span>
                      </CoverTextDisplay>
                      <CoverTextDisplay>
                        <CustomButton
                          label={Resources.cover.button.label}
                          view={Resources.cover.button.view}
                          fullWidth={false}
                          containerStyle={{ marginTop: "30px" }}
                          onClick={() => onContactClick()}
                        />
                      </CoverTextDisplay>
                    </CoverTextContainer>
                  </CoverContentSection>
                </CoverContentContainer>
              </div>
            </CoverInfoSection>
          </BackgroundImage>
      </CoverWrapper>
    )}/>)
}


const onContactClick = () => {
  const element = document.getElementById("contact-form-id")
  if (element) {
    const position = element.offsetTop - Resources.navbar.offset_top
    window.scrollTo(0, position)
  }
}

export default Cover
